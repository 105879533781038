
























































import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
@Component({
  components: {}
})
export default class MaterialCard extends Vue {
  @Prop({ default: "" }) avatar!: string;
  @Prop({ default: "success" }) color!: string;
  @Prop({ default: undefined }) icon!: string;
  @Prop({ default: false }) image!: Boolean;
  @Prop({ default: "" }) text!: string;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: true }) pading!: boolean;

  public get classes() {
    return {
      "v-card--material--has-heading": this.hasHeading
    };
  }
  public get hasHeading() {
    return Boolean(this.$slots.heading || this.title || this.icon);
  }
  public get hasAltHeading() {
    return Boolean(this.$slots.heading || (this.title && this.icon));
  }
}

