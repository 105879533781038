



















































import MaterialCard from "@/components/Cards/MaterialCard.vue";
import { RouterNames } from "@/router/routernames";
import { paciente } from "@/shared/dtos/paciente-dto";
import pacienteModule from "@/store/modules/paciente-module";
import { UtilsPaciente } from "@/utils/utils-paciente";
import { Component, Vue } from "vue-property-decorator";
import CargaDialog from "../cargando/CargaDialog.vue";
@Component({
  $_veeValidate: { validator: "new" },
  components: { MaterialCard, CargaDialog },
})
export default class Perfil extends Vue {
  created() {}
  public get user() {
    return pacienteModule.paciente;
  }
  public get stilo() {
    //this.cita.id_nutricionista
    let h = this.$vuetify.breakpoint.height - 285;
    return "height:" + h + "px;overflow: auto;";
  }
  public get tkn() {
    return pacienteModule.token;
  }

  public get getImagen() {
    return UtilsPaciente.getsexoFromObject(pacienteModule.paciente);
  }

  public save() {
    pacienteModule
      .actualizarpaciente(this.user)
      .then(() => this.$router.push({ name: RouterNames.Home }));
  }
}
